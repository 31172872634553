import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';

import {
  AccountsDrawer,
  Container,
  Grid,
  OpsDashboardHeaderV2,
  OpsDashboardTabsV2,
  OpsDashboardV2,
  OpsUserType,
} from '@sigfig/digital-wealth-core';

import contentOptions from '../../utils/contentOptions';
import { routes } from '../../utils/routes';

import { useAccountDrawerInstance } from './hooks/useAccountDrawerInstance';
import { useOpsDashboardParam } from './hooks/useOpsDashboardParams';

interface Props {
  inContextPartyId: string;
  isPendingModalChangeApproveEnabled: boolean;
  userType: OpsUserType | undefined;
}

const hiddenTabs = [
  OpsDashboardTabsV2.ACATRequests,
  OpsDashboardTabsV2.BankVerification,
  OpsDashboardTabsV2.Funding,
  OpsDashboardTabsV2.PendingModelChanges,
  OpsDashboardTabsV2.TradingSuspensions,
];

const OpsDashboardComponentV2: React.FC<Props> = ({
  userType,
  inContextPartyId,
  isPendingModalChangeApproveEnabled,
}) => {
  const { partyId } = useParams();
  const { params, onParamsUpdate } = useOpsDashboardParam(partyId ?? '');
  const {
    drawerParams,
    closeDrawer,
    handleBackClick,
    handleOnClientClick,
    handleOnAccountClick,
    currentDrawerInstance,
    currentDrawerComponent,
  } = useAccountDrawerInstance(partyId ?? '', contentOptions, inContextPartyId, isPendingModalChangeApproveEnabled);

  return (
    <>
      <OpsDashboardHeaderV2 contentOptions={contentOptions} showHelpButton showOpsUserIcon userType={userType} />
      <Container disableGutters maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OpsDashboardV2
              contentOptions={contentOptions}
              getAccountNumberRedirectUrl={routes.accountDetails}
              getClientNameRedirectUrl={routes.accountSummary}
              hiddenTabs={hiddenTabs}
              inceptionDayForFundingTab={0}
              onAccountClick={handleOnAccountClick}
              onClientClick={handleOnClientClick}
              onParamsUpdate={onParamsUpdate}
              params={params}
              partyId={partyId ?? ''}
            />
          </Grid>
          {drawerParams && currentDrawerInstance && (
            <AccountsDrawer
              contentOptions={contentOptions}
              currentDrawerComponent={currentDrawerComponent}
              currentDrawerInstance={currentDrawerInstance}
              onBackClick={handleBackClick}
              onClose={closeDrawer}
            />
          )}
        </Grid>
      </Container>
    </>
  );
};

export default OpsDashboardComponentV2;
