import { createSearchParams, generatePath } from 'react-router-dom-v5-compat';

import { OpsDashboardParams, OpsDashboardParamsV2 } from '@sigfig/digital-wealth-core';

export const generateAbsolutePath: typeof generatePath = (...args) => `${generatePath(...args)}`;

export const getSearchParams = (paramValuePair?: Record<string, string | undefined>): string => {
  const paramsWithValue = Object.entries(paramValuePair ?? {})
    .filter(([_, v]) => !!v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  return Object.keys(paramsWithValue).length ? `?${createSearchParams(paramsWithValue)}` : '';
};

export enum PageRoute {
  AccountDetails = '/account-summary/:partyId/details/:managedProductId',
  AccountPerformance = '/account-summary/:partyId/performance/:managedProductId',
  AccountSummary = '/account-summary/:partyId',
  OldOpsDashboard = '/ops-dashboard/v1/:partyId/:tab?',
  OpsDashboard = '/ops-dashboard/:partyId/:tab?',
  OpsDashboardSearch = 'ops-dashboard/search',
}

export const routes = {
  accountDetails: (partyId: string, managedProductId: string): string =>
    generateAbsolutePath(PageRoute.AccountDetails, { partyId, managedProductId }),
  accountPerformance: (partyId: string, managedProductId: string, source: string): string =>
    generateAbsolutePath(PageRoute.AccountPerformance, { partyId, managedProductId }) + getSearchParams({ source }),
  accountSummary: (partyId: string): string => generateAbsolutePath(PageRoute.AccountSummary, { partyId }),
  opsDashboard: (partyId: string, params?: OpsDashboardParams): string =>
    generateAbsolutePath(PageRoute.OpsDashboard, { partyId, tab: params?.tab ?? null }) +
    getSearchParams({ state: params?.state }),
  oldOpsDashboard: (partyId: string, params?: OpsDashboardParamsV2): string =>
    generateAbsolutePath(PageRoute.OldOpsDashboard, { partyId, tab: params?.tab ?? null }) +
    getSearchParams({ state: params?.state }),
};
