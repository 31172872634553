import {
  AccountDetailsTabsEnum,
  CoreConfig,
  defaultCoreConfig,
  PerformanceMethodTypes,
} from '@sigfig/digital-wealth-core';

export const defaultPartnerConfigs: CoreConfig = {
  ...defaultCoreConfig,
  components: {
    ...defaultCoreConfig.components,
    sfAccountDetails: {
      ...defaultCoreConfig.components.sfAccountDetails,
      showClosedAccountsInDropdown: true,
    },
    sfAccountDetailsOverview: {
      ...defaultCoreConfig.components.sfAccountDetailsOverview,
      showPerformanceCalculationInfo: true,
      performanceMethod: PerformanceMethodTypes.FROM_END_OF_DAY_VALUES,
    },
    sfAccountDetailsV2: {
      ...defaultCoreConfig.components.sfAccountDetailsV2,
      hiddenSections: ['add_funds', 'annual_review', 'clientInfo', 'questions'],
      tabs: [
        AccountDetailsTabsEnum.overview,
        AccountDetailsTabsEnum.restrictions,
        AccountDetailsTabsEnum.activity,
        AccountDetailsTabsEnum.documents,
      ],
    },
    sfAccountSummary: {
      ...defaultCoreConfig.components.sfAccountSummary,
      fallBackToStagedModelPortfolio: true,
    },
    sfActiveSuspensions: {
      ...defaultCoreConfig.components.sfActiveSuspensions,
      addPartnerOpsSuspensionAllowed: true,
      removePartnerOpsSuspensionAllowed: true,
    },
  },
};

// TODO - Temporary hack to differentiate environment config values until this is present in puppet
// TODO - This will almost certainly change.
const pi1BaseUrl = 'https://pi1-citizens-ops-dashboard.qa.sigfig.com';
const iqa1BaseUrl = 'https://iqa1-citizens-ops-dashboard.qa.sigfig.com';
const sqa1BaseUrl = 'https://advisory-connect-dashboard.qacitizensbank.com';
const sqa2BaseUrl = 'https://advisory-connect-dashboard-2.qacitizensbank.com';
const stgBaseUrl = 'https://stg-citizens-ops-dashboard.sigfig.com';
const prodBaseUrl = 'https://advisory-connect-dashboard.citizensbank.com';

const isPi1 = window.location.origin === pi1BaseUrl;
const isIqa1 = window.location.origin === iqa1BaseUrl;
const isSqa1 = window.location.origin === sqa1BaseUrl;
const isSqa2 = window.location.origin === sqa2BaseUrl;
const isStg = window.location.origin === stgBaseUrl;
const isProd = window.location.origin === prodBaseUrl;

const getConfigValue = ({
  pi1,
  iqa1,
  sqa1,
  sqa2,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  pi1?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  stg?: string;
}) => {
  if (isPi1 && pi1) {
    return pi1;
  }
  if (isIqa1 && iqa1) {
    return iqa1;
  }
  if (isSqa1 && sqa1) {
    return sqa1;
  }
  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

export default {
  // TODO - Remove temporary bypass for Auth0 rules
  fallbackJwt: getConfigValue({
    pi1:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZW5kb3IiOiJjaXRpemVucyIsImVudiI6InRlc3QiLCJhcHAiOiJESUdJVEFMX1dFQU' +
      'xUSCIsImFwaVZlcnNpb24iOiIxLjAiLCJleHAiOiIyMDIyLTExLTEyVDE2OjAwOjAwLjAwMFoiLCJpYXQiOiIyMDE5LTExLTEzVDIxOjU0OjAxL' +
      'jk0MVoifQ.6C68YDcpZCSpH43UnwbyUBo9TRC665-w-FLzlPjHLOE',
    _: '',
  }),
  // TODO - Remove temporary bypass for Auth0 rules
  inContextPartyId: getConfigValue({
    pi1: '01FNQXKZB46RZW0HWRRTN71FTQ',
    _: '',
  }),
  contentstack: {
    environment: getConfigValue({
      sqa1: 'citizens_sqa1',
      sqa2: 'citizens_prod', // This value may change
      stg: 'citizens_prod',
      prod: 'citizens_prod',
      _: 'dev', // dev, local, iqa1, pi1
    }),
    deliveryToken: getConfigValue({
      sqa1: 'csbeebf22381d29cbf99aad72c',
      // This will change same as Prod for now
      sqa2: 'cs6ed37d7a109fb4c8bada2dd6',
      stg: 'cs6ed37d7a109fb4c8bada2dd6',
      prod: 'cs6ed37d7a109fb4c8bada2dd6',
      _: 'csaffbef3139b4b24aee2cc24c', // dev, local, iqa1, pi1
    }),
  },
  auth0: {
    clientId: getConfigValue({
      pi1: 'rtRKHOVUlbt5IbNTJaGFcFjIBwLsMjUQ',
      sqa1: 'bvkuviBhVVv411Gq0DEvUA1VVNbEcudP',
      sqa2: 'oMrRhqBVTZS5O1tUfkQtwTRR1jlFcQ4D',
      stg: 'UbSLKVD5YI1r6lVMS01S2MAzvlABwHXg',
      prod: 'eLzhtG3eY6M7vzwq5To3huwx4wHYwAUq',
      _: 'honxfCa387aZFMd5RiGZksSlcIac1Lsk', // dev, local, iqa1
    }),
    domain: getConfigValue({
      pi1: 'citizens-pi1.us.auth0.com',
      sqa1: 'citizens-sqa1.us.auth0.com',
      sqa2: 'citizens-sqa2.us.auth0.com',
      stg: 'citizens-staging.us.auth0.com',
      prod: 'citizens-prod.us.auth0.com',
      _: 'citizens-iqa.us.auth0.com', // dev, local, iqa1
    }),
  },
  sentry: {
    dsn: getConfigValue({
      iqa1: 'https://ece2115333d9445ea5234bbeadf4fa41@o14749.ingest.sentry.io/6380773',
      sqa1: 'https://a19d4171621648349cbf99a3fd4b73c8@o14749.ingest.sentry.io/6394444',
      sqa2: 'https://41cd36bddfc24a419b2ed1ef6a4c7de0@o14749.ingest.sentry.io/6526249',
      stg: 'https://dea54802006d4fa19647b2bb63f25b5d@o14749.ingest.sentry.io/6526250',
      prod: 'https://81b0bd5a14014924908f2a7c5286d9c7@o14749.ingest.sentry.io/6526251',
      _: 'https://f03d680f68a44524a531560c909911df@o14749.ingest.sentry.io/6380774', // dev, local, pi1
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_QTYlV8sjyedwhJzMlZ6hpqTO4MoucyHpjOEDQpltO0s',
      _: 'phc_ejNgsESdrWuFpgLCf4GTqEs6OUgcFEkNOGTrh80K14t',
    }),
  },
  baseUrl: getConfigValue({
    pi1: pi1BaseUrl,
    sqa1: sqa1BaseUrl,
    sqa2: sqa2BaseUrl,
    stg: stgBaseUrl,
    prod: prodBaseUrl,
    _: iqa1BaseUrl, // dev, local, iqa1
  }),
};
