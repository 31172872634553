import React, { useContext } from 'react';

import { ContentOptions, Locale, ManagedProductType, Partner, Product } from '@sigfig/digital-wealth-core';

export interface AppContext {
  contentOptions: ContentOptions;
  program: ManagedProductType;
}

const defaultAppContext: AppContext = {
  contentOptions: {
    locale: Locale.en_us,
    partner: Partner.Citizens,
    product: Product.OpsDashboard,
  },
  program: ManagedProductType.DIGITAL_ADVICE_PRO,
};

const AppContext = React.createContext(defaultAppContext);

export const AppProvider: React.FC = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        contentOptions: { locale: Locale.en_us, partner: Partner.Citizens, product: Product.OpsDashboard },
        program: ManagedProductType.DIGITAL_ADVICE_PRO,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): { contentOptions: ContentOptions; program: ManagedProductType } => {
  if (AppContext === undefined) {
    throw new Error('useApp was used without AppProvider');
  }
  const { contentOptions, program } = useContext(AppContext);
  return { contentOptions, program };
};
