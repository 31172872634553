/* eslint-disable no-undef */
import './index.css';
import './theme/fonts/citi-sans/index.css';
import './theme/fonts/entypo/index.css';
import './theme/fonts/opensans/index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { CompatRouter, useNavigate } from 'react-router-dom-v5-compat';

import { Error, PostHogProvider, sfPosthog } from '@sigfig/digital-wealth-core';

import App from './App';
import config from './config';
import * as serviceWorker from './serviceWorker';

if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = appState => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

ReactDOM.render(
  <Router>
    <CompatRouter>
      <Auth0ProviderWithRedirectCallback
        clientId={config.auth0.clientId}
        domain={config.auth0.domain}
        redirectUri={window.location.origin}
      >
        <Sentry.ErrorBoundary fallback={Error}>
          <PostHogProvider apiKey={config.posthog.token} options={sfPosthog.DEFAULT_CONFIG}>
            <App />
          </PostHogProvider>
        </Sentry.ErrorBoundary>
      </Auth0ProviderWithRedirectCallback>
    </CompatRouter>
  </Router>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
