import { alpha, createModernThemeOptions, ThemeOptions } from '@sigfig/digital-wealth-core';

const paletteText = {
  primary: '#000000',
  secondary: '#6F7779',
};

const palettePrimary = {
  contrastText: '#FFFFFF',
  main: '#00945f',
};

const palette = {
  primary: {
    ...palettePrimary,
    background: alpha(palettePrimary.main, 0.08),
    selected: alpha(palettePrimary.main, 0.08),
    border: alpha(palettePrimary.main, 0.5),
  },
  error: {
    main: '#F44336',
  },
  text: {
    ...paletteText,
  },
  other: {
    neutral: '#F0F0F0',
  },
};

const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableElevation: false,
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        '&.MuiButton-contained': {
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: palette.other.neutral,
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);',
          },
        },
      },
      sizeLarge: {
        fontSize: '16px',
      },
      sizeMedium: {
        fontSize: '14px',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        color: alpha(palette.text.primary, 0.23),
        minHeight: '54px',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      button: {
        fontSize: '14px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        paddingTop: '15.5px',
        paddingBottom: '15.5px',
      },
    },
  },
  MuiPagination: {
    defaultProps: {
      color: 'standard',
      size: 'large',
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: palette.primary.background,
        },
      },
    },
  },
};

enum FontFamilies {
  citisans = 'CitiSans Book',
  sans = 'arial, sans-serif',
}

const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: FontFamilies.citisans,
    fontSize: 42,
    fontWeight: 400,
    letterSpacing: '-0.2px',
    lineHeight: '56px',
  },
  h2: {
    fontFamily: FontFamilies.citisans,
    fontSize: 36,
    fontWeight: 400,
    letterSpacing: '-0.2px',
    lineHeight: '52px',
  },
  h3: {
    fontFamily: FontFamilies.citisans,
    fontSize: 32,
    fontWeight: 400,
    letterSpacing: '-0.2px',
    lineHeight: '48px',
  },
  h4: {
    fontFamily: FontFamilies.citisans,
    fontSize: 24,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: '36px',
  },
  h5: {
    fontFamily: FontFamilies.citisans,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '36px',
  },
  h6: {
    fontFamily: FontFamilies.citisans,
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  subtitle1: {
    fontFamily: FontFamilies.sans,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  subtitle2: {
    fontFamily: FontFamilies.sans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  body1: {
    fontFamily: FontFamilies.sans,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: FontFamilies.sans,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  button: {
    fontFamily: FontFamilies.sans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  caption: {
    fontFamily: FontFamilies.sans,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '16px',
  },
  overline: {
    fontFamily: FontFamilies.sans,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '1px',
    lineHeight: '20px',
  },
};

const sfComponents: ThemeOptions = {
  sfAccount: {
    styles: {},
    typographyVariants: {
      portfolioName: 'subtitle2',
      balance: 'subtitle2',
      ytd: 'subtitle2',
    },
  },
  sfAccountDetails: {
    styles: {},
    typographyVariants: {
      headerTitle: 'h4',
    },
  },
  sfAccountInfo: {
    styles: {
      accountProgramLink: {
        color: 'info.dark',
        textDecoration: 'underline',
      },
      subHeading: {
        color: 'text.secondary',
      },
    },
  },
  sfAccountList: {
    styles: {},
    typographyVariants: {
      heading: 'h5',
    },
  },
  sfAccountTotal: {
    styles: {},
    typographyVariants: {
      heading: 'h5',
      balance: 'h5',
    },
  },
  sfModal: {
    root: {},
    styles: {},
    typographyVariants: {
      title: 'h6',
    },
  },
  sfOpsDashboard: {
    styles: {
      tabsContainer: {
        background: '#363F50',
      },
      chip: {
        label: {
          cancelled: { backgroundColor: 'error.main', color: 'error.contrastText' },
          completed: { backgroundColor: 'success.lightBg', color: 'success.textDark' },
          default: { backgroundColor: 'other.divider' },
          active: { backgroundColor: 'warning.lightBg', color: 'warning.textDark' },
          declined: { backgroundColor: 'error.dark', color: 'error.textDark' },
          succeeded: { backgroundColor: 'success.dark', color: 'success.contrastText' },
          failed: { backgroundColor: 'error.dark', color: 'error.contrastText' },
          approved: { backgroundColor: 'success.lightBg', color: 'success.textDark' },
          rejected: { backgroundColor: 'error.dark', color: 'error.contrastText' },
        },
        root: {},
      },
      tabs: {
        padding: 0,
      },
      tab: {
        '&.Mui-selected': {
          backgroundColor: palette.primary.background,
          color: palette.primary.main,
        },
      },
      opsHeaderLogo: {
        height: '17px',
        width: '100%',
      },
    },
  },
  sfPoweredByFooter: {
    styles: {
      partnerLogo: {
        height: '18px',
        width: '117px',
      },
    },
  },
  sfPerformanceChart: {
    styles: {
      linkDecoration: 'none',
      lineColor: palette.text.secondary,
    },
  },
};

export const modernThemeOptions = createModernThemeOptions({
  components,
  palette,
  typography,
  ...sfComponents,
});
