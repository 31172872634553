import React from 'react';
import { Redirect } from 'react-router-dom';

import { useApp } from '../../contexts/App';

/*
    To handle logic for entry into the app.
    Right now it is simple, redirect to widgets if they came in through the widget, otherwise go to onboarding
*/

export interface Props {
  from?: string;
  partyId?: string;
}
export const RouteEntry: React.FC<Props> = ({ partyId = '', from }): any => {
  const { contentOptions } = useApp();
  return <Redirect to={`/ops-dashboard/${partyId}`} />;
};
