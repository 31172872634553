import { Application } from './types/Application';
import { ClaimSet } from './types/ClaimSet';
import { Operation, Role, RolePermission, RoleScope } from './types/Role';

const ALL_RESOURCES = 'All';

/**
 * Check if the given resource and operation combination are permitted within the given permission
 */
export const checkPermission = (resourceType: string, op: Operation, permission: RolePermission): boolean =>
  permission.ops.includes(op) && (permission.rsc.includes(ALL_RESOURCES) || permission.rsc.includes(resourceType));

/**
 * Check if the given resource and operation combination are permitted within the given role permissions
 */
export const checkRolePermissions = (resourceType: string, op: Operation, role: Role): boolean =>
  role.prm.some((nextPermission: RolePermission) => checkPermission(resourceType, op, nextPermission));

/**
 * Check if the given resource and operation combination are permitted within any of the given array of role permissions
 */
export const hasResourceAccess = (resourceType: string, op: Operation, roles: Role[]): boolean =>
  roles.some(role => checkRolePermissions(resourceType, op, role));

/**
 * Check if the given scope, resource and operation combination are permitted within any of the given array of role permissions
 */
export const canAccessScope = (scope: RoleScope, resourceType: string, op: Operation, roles: Role[]): boolean =>
  roles.some(role => role.scp.includes(scope) && checkRolePermissions(resourceType, op, role));

/**
 * A convenience wrapper around [[canAccessScope]] for "SELF" scoped assertions
 */
export const canAccessSelf = (resourceType: string, op: Operation, roles: Role[]): boolean =>
  canAccessScope(RoleScope.Self, resourceType, op, roles);

/**
 * A convenience wrapper around [[canAccessScope]] for "PARTNER" scoped assertions
 */
export const canAccessFullPartner = (resourceType: string, op: Operation, roles: Role[]): boolean =>
  canAccessScope(RoleScope.Partner, resourceType, op, roles);

/**
 * A convenience wrapper around [[canAccessScope]] for "RELATIONSHIP" scoped assertions
 */
export const canAccessRelationship = (resourceType: string, op: Operation, roles: Role[]): boolean =>
  canAccessScope(RoleScope.Relationship, resourceType, op, roles);

/**
 * Return an array of all roles for a given applicationId
 */
export const getApplicationRoles = (applicationId: string, claimSet: ClaimSet): Role[] => {
  const app: Application | undefined = claimSet.app && claimSet.app[applicationId];
  return app ? app.rol : [];
};
