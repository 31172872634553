export interface Role {
  /**
   * @name RoleFilters
   */
  flt?: Record<string, RoleFilterDescriptor | Array<string>>;
  /**
   * @name RoleLists
   */
  lst?: Record<string, RoleList>;
  /**
   * @name RolePermissions
   */
  prm: Array<RolePermission>;
  /**
   * @name RoleName
   */
  rln: string;
  /**
   * @name RoleScopes
   */
  scp: Array<RoleScope>;
}

export enum RoleScope {
  /**
   * @description Constrains the data to all data that matches the filters in the filter role claim (`flt`)
   */
  Filtered = 'FILTERED',
  /**
   * @description TBD
   */
  Impersonation = 'IMPERSONATION',
  /**
   * @description Constrains the data to the elements explicitly defined in the list role claim (`lst`)
   */
  List = 'LIST',
  /**
   * @description Data across the entire partner
   */
  Partner = 'PARTNER',
  /**
   * @description Constrains the data to all data that matches the filters in the relationship filter role claim (`flt[ResourceType].rel`)
   */
  Relationship = 'RELATIONSHIP',
  /**
   * @description Constrains the data to that belonging to the primary subject of the claim
   */
  Self = 'SELF',
}

export interface RolePermission {
  /**
   * @name Operations
   */
  ops: Array<Operation>;
  /**
   * @name Resources
   */
  rsc: Array<'All' | string>; // Operations
}

export enum Operation {
  Get = 'Get',
  Patch = 'Patch',
  Post = 'Post',
  Put = 'Put',
}

export interface RoleFilterDescriptor {
  /**
   * @name Attributes
   */
  attr?: Array<RelationshipFilter>;
  /**
   * @name Relationships
   */
  rel?: Array<RelationshipFilter>;
}

export interface RelationshipFilter {
  /**
   * @name Relationship Name
   */
  nme: string;
  /**
   * @name Related Role
   */
  rrl: string;
  /**
   * @name Relationship Subject
   */
  sub: string;
}

/**
 * @name RoleList
 */
export type RoleList = Record<string, ResourceGroup> | Array<string>;

/**
 * @name ResourceGroup
 */
export type ResourceGroup = Record<string, Array<string>>;
