/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { History, Location } from 'history';
import jwtDecode from 'jwt-decode';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import {
  AccountDetailsV2,
  ApolloProvider,
  AssetClassTier,
  Box,
  cacheTypePolicies as symphonyCacheTypePolicies,
  CircularProgress,
  ClientInfo,
  Container,
  CoreConfigProvider,
  createTheme,
  DisclosurePage,
  ErrorComponent,
  from,
  getApolloClient,
  getContentstackHttpLinkConfig,
  Grid,
  HttpLink,
  InMemoryCache,
  isPartnerOpsSuspension,
  ManagedProductType,
  OpsUserType,
  PerformanceMethodTypes,
  setContext,
  sfPosthog,
  ThemeProvider,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { KeepAlive } from './auth/KeepAlive';
import { RouteEntry } from './components/RouteEntry';
import config, { defaultPartnerConfigs } from './config';
import OpsDashboardComponent from './containers/OpsDashboard';
import OpsDashboardComponentV2 from './containers/OpsDashboardV2';
import { AppProvider } from './contexts/App';
import { canAccessFullPartner, getApplicationRoles } from './rbac';
import { ClaimSet } from './rbac/types/ClaimSet';
import { Operation } from './rbac/types/Role';
import { modernThemeOptions } from './theme';
import { accountsFilter, getAccountActions } from './utils/account';
import contentOptions from './utils/contentOptions';
import { PageRoute } from './utils/routes';

export enum AccountActionRoutes {
  ACCOUNT_DETAILS = 'AccountDetails',
  ACCOUNT_SUMMARY = 'AccountSummary',
}

const OpsDashboardHeaderV2 = lazy(() => import('./components/OpsDashboardHeader'));

const AccountSummary = lazy(() => import('./containers/AccountSummary'));

const ClientPortfolioSearch = lazy(() => import('./components/ClientPortfolioSearch'));

const Disclosure = lazy(() => import('./components/Disclosure'));

const ProtectedRoute = lazy(() => import('./auth').then(({ ProtectedRoute }) => ({ default: ProtectedRoute })));

const ProtectedCompatRoute = lazy(() =>
  import('./auth').then(({ ProtectedCompatRoute }) => ({ default: ProtectedCompatRoute })),
);

function App(): unknown {
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0();
  const [jwt, setJwt] = useState('');
  const [inContextPartyId, setInContextPartyId] = useState('');
  const [externalPartyId, setExternalPartyId] = useState('');
  const [userType, setUserType] = useState<OpsUserType>();
  const [isPendingModalChangeApproveEnabled, setIsPendingModalChangeApproveEnabled] = useState<boolean>();

  const applicationId = 'ADVISOR_TOOLS';
  const namespace = 'https://fc.sigfig.com';
  const posthog = usePostHog();
  sfPosthog.useCaptureSpaPageViews(useLocation());

  useEffect(() => {
    if (isAuthenticated && user) {
      const jwt = user[`${namespace}:frontEndJwt`];
      if (jwt) {
        setJwt(jwt);
        const decodedJwt = jwtDecode(jwt) as ClaimSet;
        const roles = getApplicationRoles(applicationId, decodedJwt);
        if (roles.find(role => role.rln === 'Principal')) {
          setUserType(OpsUserType.PRINCIPAL);
        } else {
          setUserType(OpsUserType.OPERATIONS);
        }
        setInContextPartyId(user[`${namespace}:inContextPartyId`]);
        setExternalPartyId(user[`${namespace}:externalPartyId`]);
        setIsPendingModalChangeApproveEnabled(
          canAccessFullPartner('PLAN_UPDATE_WORKFLOW_APPROVAL_REQUEST', Operation.Patch, roles),
        );
      } else {
        setUserType(OpsUserType.PRINCIPAL);
        setJwt(config.fallbackJwt);
        setInContextPartyId(config.inContextPartyId);
        setExternalPartyId(config.inContextPartyId);
        setIsPendingModalChangeApproveEnabled(true);
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const contextPartyId = user?.['https://fc.sigfig.com:inContextPartyId'];
    if (contextPartyId && inContextPartyId !== contextPartyId) {
      setInContextPartyId(contextPartyId);
    }
    posthog?.identify(contextPartyId);
  }, [user, inContextPartyId, posthog]);

  // To pass the jwt token to axios, needed while downloading client documents
  useEffect(() => {
    axios.defaults.headers.common.Authorization = jwt ? `Bearer ${jwt}` : '';
  }, [jwt]);

  // TODO - Fix apollo client parameters
  const getSymphonyHttpLinkConfig = useCallback(() => {
    const symphonyUrl = process.env.NODE_ENV === 'development' ? '/ui/graphql' : '/symphony/ui/graphql';
    const symphonyAuthLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      };
    });

    const errorLink = onError(({ networkError }) => {
      if (
        (networkError as any)?.result?.status === 403 &&
        (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
      ) {
        posthog?.reset();
        logout({ returnTo: window.location.origin });
      }
    });

    return {
      name: 'symphony',
      link: from([
        errorLink,
        symphonyAuthLink.concat(
          new HttpLink({
            uri: symphonyUrl,
            fetch,
          }),
        ),
      ]),
    };
  }, [jwt, logout, posthog]);

  const client = useMemo(
    () =>
      getApolloClient({
        links: [
          getContentstackHttpLinkConfig(config.contentstack.environment, config.contentstack.deliveryToken),
          getSymphonyHttpLinkConfig(),
        ],
        cache: new InMemoryCache({
          typePolicies: {
            ...symphonyCacheTypePolicies,
          },
        }),
      }),
    [getSymphonyHttpLinkConfig],
  );

  if (isLoading || (isAuthenticated && jwt === '')) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <ErrorComponent
        contentOptions={contentOptions}
        message="Sorry, you are not entitled to view this page."
        suggestion="Please check with your systems administrator or manager to request access."
      />
    );
  }

  const showBackToAccountSummary = (location: Location) => location.pathname.includes('/details');
  const redirectToAccountSummary = (history: History, partyId: string) => history.push(`/account-summary/${partyId}`);
  const redirectToClientList = (history: History) => history.push(`/ops-dashboard/${inContextPartyId}`);

  const DisclosureComponent: React.FC<{ page: DisclosurePage }> = ({ page }) => (
    <Grid container justifyContent="center" sx={{ backgroundColor: 'background.default', mt: 7 }}>
      <Grid item sx={{ py: 7 }} xs={8}>
        <Disclosure contentOptions={contentOptions} page={page} />
      </Grid>
    </Grid>
  );

  const SearchComponent = ({ history, location }: RouteComponentProps) => (
    <>
      <OpsDashboardHeaderV2
        contentOptions={contentOptions}
        onViewClientListClick={() => redirectToClientList(history)}
        showHelpButton
        showListViewInSubNav
        showOpsUserIcon
        userType={userType}
      />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ClientPortfolioSearch
              contentOptions={contentOptions}
              onClientClick={(partyId: string) => history.push(`/account-summary/${partyId}`)}
              searchAccountsFilter={accountsFilter}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );

  const ManagedAccounts = ({
    history,
    location,
    match: {
      path,
      params: { partyId },
    },
  }: RouteComponentProps<{ managedProductId: string; partyId: string }>) => {
    const accountSummaryRouteMatch = useRouteMatch(path);
    const accountDetailsRouteMatch = useRouteMatch<{ managedProductId: string }>(`${path}/details/:managedProductId`);
    const managedProductId = accountDetailsRouteMatch?.params.managedProductId;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <OpsDashboardHeaderV2
            contentOptions={contentOptions}
            onBackToAccountSummaryClick={() => redirectToAccountSummary(history, partyId)}
            onViewClientListClick={() => redirectToClientList(history)}
            showBackToAccountSummaryInSubNav={showBackToAccountSummary(location)}
            showHelpButton
            showOpsUserIcon
            userType={userType}
          />
        </Grid>
        <Grid item xs={11}>
          <ClientInfo contentOptions={contentOptions} partyId={partyId} />
        </Grid>
        <Grid item xs={11}>
          {accountSummaryRouteMatch?.isExact && (
            <AccountSummary
              accountDetailsToShow={{
                showOwnershipType: false,
                showModelPortfolioInfo: true,
              }}
              accountProps={{ isPendingModelChangeApproveEnabled: isPendingModalChangeApproveEnabled }}
              accountSummaryGetDigitalWealthAccountsVariables={{
                partyId,
              }}
              accountsFilter={accountsFilter}
              actions={getAccountActions(history, AccountActionRoutes.ACCOUNT_SUMMARY)}
              assetClassTier={(productType: ManagedProductType) =>
                productType === ManagedProductType.DIGITAL_ADVICE_PRO ? AssetClassTier.ENCLOSING : AssetClassTier.MODEL
              }
              contentOptions={contentOptions}
              ctasOverrides={{
                continuePendingAccount: '',
                raiseCash: 'Raise Cash',
              }}
              hiddenSections={['clientInfo', 'supportQuestions']}
              partyId={partyId}
              performanceMethod={PerformanceMethodTypes.FROM_END_OF_DAY_VALUES}
              tradingSuspensionsFilter={isPartnerOpsSuspension}
              viewerPartyId={inContextPartyId}
            />
          )}
          {accountDetailsRouteMatch?.isExact && managedProductId && (
            <AccountDetailsV2
              actions={getAccountActions(history, AccountActionRoutes.ACCOUNT_DETAILS)}
              contentOptions={contentOptions}
              getAssetClassTier={(productType: ManagedProductType) =>
                productType === ManagedProductType.DIGITAL_ADVICE_PRO ? AssetClassTier.ENCLOSING : AssetClassTier.MODEL
              }
              managedProductId={managedProductId}
              onRetakeQuestionnaire={() => history.push(`/rce/${partyId}/questionnaire/${managedProductId}`)}
              partyId={partyId}
              tradingSuspensionsFilter={isPartnerOpsSuspension}
            />
          )}
        </Grid>
        <DisclosureComponent page="RCE" />
      </Grid>
    );
  };

  const EntryComponent = ({ history, location }: RouteComponentProps) => {
    return (
      <>
        <OpsDashboardHeaderV2 contentOptions={contentOptions} showHelpButton showOpsUserIcon userType={userType} />
        <RouteEntry partyId={inContextPartyId} />
      </>
    );
  };

  const NewOpsDashboardComponent = () => {
    return (
      <OpsDashboardComponentV2
        inContextPartyId={inContextPartyId}
        isPendingModalChangeApproveEnabled={!!isPendingModalChangeApproveEnabled}
        userType={userType}
      />
    );
  };

  return (
    <AppProvider>
      <CoreConfigProvider value={defaultPartnerConfigs}>
        <ThemeProvider theme={createTheme(modernThemeOptions)}>
          <ApolloProvider client={client}>
            <KeepAlive>
              <Suspense
                fallback={
                  <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress disableShrink />
                  </Box>
                }
              >
                <Switch>
                  <ProtectedRoute component={ManagedAccounts} path="/account-summary/:partyId" />
                  <ProtectedRoute component={SearchComponent} path="/ops-dashboard/search" />
                  <ProtectedCompatRoute component={OpsDashboardComponent} path={PageRoute.OldOpsDashboard} />
                  <ProtectedCompatRoute component={NewOpsDashboardComponent} path={PageRoute.OpsDashboard} />
                  <ProtectedRoute component={EntryComponent} exact path="/" />
                  {/* TODO - Add page not found component */}
                </Switch>
              </Suspense>
            </KeepAlive>
          </ApolloProvider>
        </ThemeProvider>
      </CoreConfigProvider>
    </AppProvider>
  );
}
export default App;
