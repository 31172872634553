import { useCallback, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom-v5-compat';

import { OpsDashboardParams, OpsDashboardParamsV2, OpsDashboardTabs } from '@sigfig/digital-wealth-core';

import { routes } from '../../../../utils/routes';

const isValidTab = (tab: string | undefined): OpsDashboardTabs | undefined =>
  Object.values(OpsDashboardTabs).find(value => value === tab);

export const useOldOpsDashboardParams = (partyId: string) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tab } = useParams();
  const params = useMemo(
    (): OpsDashboardParams => ({
      tab: isValidTab(tab),
      state: searchParams.get('state') ?? undefined,
    }),
    [searchParams, tab],
  );

  const onParamsUpdate = useCallback(
    (updatedParams: OpsDashboardParams, isReplace?: boolean) => {
      navigate(routes.oldOpsDashboard(partyId, updatedParams as OpsDashboardParamsV2), { replace: !!isReplace });
    },
    [navigate, partyId],
  );

  return {
    onParamsUpdate,
    params,
  };
};
