/* eslint-disable @typescript-eslint/no-empty-function */
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionTimeoutMessage, usePostHog } from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';

interface Props {
  children: ReactNode;
}

export const KeepAlive: React.FC<Props> = ({ children }) => {
  const { contentOptions } = useApp();
  const { isAuthenticated, user, logout } = useAuth0();
  const timeoutMessageRef = useRef({ resetSession: () => {} });
  const history = createBrowserHistory();
  const sessionTimeout = user?.['https://fc.sigfig.com:sessionTimeout'];
  const posthog = usePostHog();
  const callKeepAlive = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const userJwt = user?.['https://fc.sigfig.com:frontEndJwt'];
        await axios({
          method: 'post',
          url: '/symphony/auth/v1/keep-alive',
          headers: {
            Authorization: `Bearer ${userJwt}`,
          },
        });
        // on any session refresh, ensure to reset the timeout message to start over again.
        timeoutMessageRef.current.resetSession();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    ['click', 'wheel'].forEach(event => window.addEventListener(event, debounce(callKeepAlive, 1000)));
    return () => {
      ['click', 'wheel'].forEach(event => window.removeEventListener(event, debounce(callKeepAlive, 1000)));
    };
  }, [callKeepAlive]);

  // TODO - Remove split logic and get partyId from match instead of pathname
  const location = useLocation();
  const partyId = location.pathname.split('/')[2];
  let sessionTimeoutRedirectUrl = '/home';
  if (partyId) {
    sessionTimeoutRedirectUrl = `/account-summary/${partyId}`;
  }

  return (
    <>
      {children}
      <SessionTimeoutMessage
        contentOptions={contentOptions}
        logoutCallback={() => {
          setTimeout(() => {
            posthog?.reset();
            logout({ returnTo: window.location.origin });
          }, 5000);
        }}
        navigateCallback={() => {
          history.push(sessionTimeoutRedirectUrl);
          window.location.reload();
        }}
        ref={timeoutMessageRef}
        refreshCallback={callKeepAlive}
        timeOutInSeconds={sessionTimeout}
        timeoutThreshold={100}
      />
    </>
  );
};
