import { useCallback, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom-v5-compat';

import { OpsDashboardParams, OpsDashboardParamsV2, OpsDashboardTabsV2 } from '@sigfig/digital-wealth-core';

import { routes } from '../../../../utils/routes';

const isValidV2Tab = (tab: string | undefined): OpsDashboardTabsV2 | undefined =>
  Object.values(OpsDashboardTabsV2).find(value => value === tab);

export const useOpsDashboardParam = (partyId: string) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tab } = useParams();

  const params = useMemo(
    (): OpsDashboardParamsV2 => ({
      tab: isValidV2Tab(tab),
      state: searchParams.get('state') ?? undefined,
    }),
    [searchParams, tab],
  );

  const onParamsUpdate = useCallback(
    (updatedParams: OpsDashboardParamsV2, isReplace?: boolean) => {
      navigate(routes.opsDashboard(partyId, updatedParams as OpsDashboardParams), { replace: !!isReplace });
    },
    [navigate, partyId],
  );

  return {
    onParamsUpdate,
    params,
  };
};
