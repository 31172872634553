import { History } from 'history';
import { ComponentProps } from 'react';

import {
  AccountAction,
  AccountActions,
  AccountState,
  FinancialAccountStatus,
  TransferFrequency,
  VISIBLE_FINANCIAL_ACCOUNT_STATUSES,
} from '@sigfig/digital-wealth-core';

import { AccountActionRoutes } from '../../App';

export const getAccountActions = (
  history: History,
  route: AccountActionRoutes,
): ComponentProps<typeof AccountActions>['config'] => {
  const preSigningAccountStates = [
    AccountState.OnboardingIncomplete,
    AccountState.OnboardingWaitingForDocs,
    AccountState.DocsReady,
    AccountState.DocsAndAccountReady,
  ];

  const postSigningAccountAction = (
    allowForPendingClosureState = true,
    allowForSaveAndGrowAccounts = true,
    allowForDocsSignedState = true,
    allowForDocsErrorState = true,
  ): AccountAction => {
    const invalidStates = [
      ...preSigningAccountStates,
      ...[AccountState.Closed],
      ...(allowForPendingClosureState ? [] : [AccountState.PendingClosed]),
      ...(allowForDocsSignedState ? [] : [AccountState.DocsSigned]),
      ...(allowForDocsErrorState ? [] : [AccountState.DocsError]),
    ];
    return {
      type: 'standard',
      valid: ({ state, attributes }) =>
        !!state &&
        !invalidStates.includes(state) &&
        (allowForSaveAndGrowAccounts ||
          !attributes?.find(
            attribute =>
              attribute.__typename === 'BooleanAttribute' &&
              attribute.name === 'IS_SAVE_AND_GROW' &&
              attribute.booleanValue,
          )),
    };
  };

  switch (route) {
    case AccountActionRoutes.ACCOUNT_SUMMARY:
    case AccountActionRoutes.ACCOUNT_DETAILS:
      return {
        closeAccount: postSigningAccountAction(false),
        openNewAccount: {
          type: 'standard',
          valid: () => false,
        },
        raiseCash: {
          ...postSigningAccountAction(false, false, false, false),
          props: {
            availableTransferFrequencies: [TransferFrequency.ONE_TIME, TransferFrequency.MONTHLY],
            soonestPossibleDays: 2,
          },
        },
        addRestrictions: postSigningAccountAction(),
        viewTransfers: postSigningAccountAction(),
        discardPendingAccount: {
          type: 'custom',
          callback: () => undefined,
          valid: () => false,
        },
        other: postSigningAccountAction(),
        viewRiskPreferenceHistory: postSigningAccountAction(),
        seeDetails: {
          type: 'custom',
          callback: args => history.push(`/account-summary/${args?.partyId}/details/${args?.managedProductId}`),
        },
      } as ComponentProps<typeof AccountActions>['config'];

    default:
      return {};
  }
};

export const accountsFilter = (status: FinancialAccountStatus): boolean => {
  return VISIBLE_FINANCIAL_ACCOUNT_STATUSES.includes(status);
};
