import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';

import {
  Container,
  Grid,
  OpsDashboard,
  OpsDashboardHeaderV2,
  OpsDashboardTabs,
  OpsUserType,
} from '@sigfig/digital-wealth-core';

import contentOptions from '../../utils/contentOptions';
import { routes } from '../../utils/routes';

import { useOldOpsDashboardParams } from './hooks/useOldOpsDashboardParams';

interface Props extends RouteComponentProps<{ partyId: string }> {
  userType: OpsUserType | undefined;
}

const hiddenTabs = [
  OpsDashboardTabs.ACATRequests,
  OpsDashboardTabs.BankVerification,
  OpsDashboardTabs.Docusign,
  OpsDashboardTabs.Errors,
  OpsDashboardTabs.Funding,
  OpsDashboardTabs.PendingModelChanges,
];

const OpsDashboardComponent: React.FC<Props> = ({ userType }) => {
  const { partyId } = useParams();
  const { params, onParamsUpdate } = useOldOpsDashboardParams(partyId ?? '');
  const navigate = useNavigate();
  const redirectToAccountDetails = (id: string, managedProductId: string) =>
    navigate(routes.accountDetails(id, managedProductId));
  const redirectToAccountSummary = (id: string) => navigate(routes.accountSummary(id));

  return (
    <>
      <OpsDashboardHeaderV2 contentOptions={contentOptions} showHelpButton showOpsUserIcon userType={userType} />
      <Container disableGutters maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OpsDashboard
              contentOptions={contentOptions}
              getAccountNumberRedirectUrl={(id: string, managedProductId: string) =>
                routes.accountDetails(id, managedProductId)
              }
              getClientNameRedirectUrl={(id: string) => routes.accountSummary(id)}
              hiddenTabs={hiddenTabs}
              onAccountClick={(id: string, managedProductId: string) => redirectToAccountDetails(id, managedProductId)}
              onClientClick={(id: string) => redirectToAccountSummary(id)}
              onParamsUpdate={onParamsUpdate}
              params={params}
              partyId={partyId ?? ''}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OpsDashboardComponent;
