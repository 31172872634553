import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AccountDetailsTabsEnum,
  AccountDetailsV2,
  AccountSummary,
  AssetClassTier,
  ClientInfo,
  ContentOptions,
  DrawerInstance,
  DrawerParams,
  Grid,
  isPartnerOpsSuspension,
  ManagedProductType,
  PerformanceMethodTypes,
} from '@sigfig/digital-wealth-core';

import { AccountActionRoutes } from '../../../../App';
import { accountsFilter, getAccountActions } from '../../../../utils/account';

const accountDetailsToShow = {
  showOwnershipType: false,
  showModelPortfolioInfo: true,
};
const ctasOverrides = {
  continuePendingAccount: '',
  raiseCash: 'Raise Cash',
};

export const useAccountDrawerInstance = (
  loggedInPartyId: string,
  contentOptions: ContentOptions,
  inContextPartyId: string,
  isPendingModalChangeApproveEnabled: boolean,
) => {
  const history = useHistory();
  const accountDetailsConfigActions = getAccountActions(history, AccountActionRoutes.ACCOUNT_DETAILS);
  const accountSummaryConfigActions = getAccountActions(history, AccountActionRoutes.ACCOUNT_SUMMARY);

  const [drawerParams, setDrawerParams] = useState<DrawerParams>();
  const [currentDrawerInstance, setCurrentDrawerInstance] = useState<DrawerInstance | undefined>(undefined);

  const closeDrawer = useCallback(() => {
    setDrawerParams(undefined);
    setCurrentDrawerInstance(undefined);
  }, []);

  const handleOnAccountClick = useCallback(
    (partyId: string, managedProductId: string, initialTab?: AccountDetailsTabsEnum) => {
      setCurrentDrawerInstance(DrawerInstance.ACCOUNT_DETAIL);
      setDrawerParams({ partyId, managedProductId, initialTab });
    },
    [],
  );

  const getAssetClassTier = useCallback((productType: ManagedProductType) => {
    return productType === ManagedProductType.DIGITAL_ADVICE_PRO ? AssetClassTier.ENCLOSING : AssetClassTier.MODEL;
  }, []);

  const handleOnRetakeQuestionnaire = useCallback(
    () => history.push(`/rce/${drawerParams?.partyId}/questionnaire/${drawerParams?.managedProductId}`),
    [drawerParams, history],
  );

  const handleOnClientClick = useCallback((partyId: string) => {
    setCurrentDrawerInstance(DrawerInstance.ACCOUNT_SUMMARY);
    setDrawerParams({ partyId });
  }, []);

  const handleBackClick = useCallback(() => {
    const previousInstance =
      currentDrawerInstance === DrawerInstance.ACCOUNT_DETAIL
        ? DrawerInstance.ACCOUNT_SUMMARY
        : DrawerInstance.ACCOUNT_DETAIL;
    setCurrentDrawerInstance(previousInstance);
  }, [currentDrawerInstance]);

  const accountDetailsActions = useMemo(() => {
    return {
      ...accountDetailsConfigActions,
      seeDetails: {
        type: 'custom',
        callback: (args: { managedProductId: string; partyId: string }) => {
          return handleOnAccountClick(args.partyId, args.managedProductId, AccountDetailsTabsEnum.overview);
        },
      },
    } as ComponentProps<typeof AccountDetailsV2>['actions'];
  }, [accountDetailsConfigActions, handleOnAccountClick]);
  const accountSummaryActions = useMemo(() => {
    return {
      ...accountSummaryConfigActions,
      seeDetails: {
        type: 'custom',
        callback: (args: { managedProductId: string; partyId: string }) => {
          return handleOnAccountClick(args.partyId, args.managedProductId, AccountDetailsTabsEnum.overview);
        },
      },
    } as ComponentProps<typeof AccountSummary>['actions'];
  }, [accountSummaryConfigActions, handleOnAccountClick]);

  const currentDrawerComponent = useMemo(() => {
    switch (currentDrawerInstance) {
      case DrawerInstance.ACCOUNT_DETAIL:
        return (
          <Grid item>
            <AccountDetailsV2
              actions={accountDetailsActions}
              contentOptions={contentOptions}
              getAssetClassTier={getAssetClassTier}
              initialState={{ initialTab: drawerParams?.initialTab }}
              managedProductId={drawerParams?.managedProductId ?? ''}
              onRetakeQuestionnaire={handleOnRetakeQuestionnaire}
              partyId={drawerParams?.partyId ?? ''}
              tradingSuspensionsFilter={isPartnerOpsSuspension}
            />
          </Grid>
        );
      case DrawerInstance.ACCOUNT_SUMMARY:
        return (
          <>
            <Grid item>
              <ClientInfo contentOptions={contentOptions} partyId={drawerParams?.partyId ?? ''} />
            </Grid>
            <Grid item>
              <AccountSummary
                accountDetailsToShow={accountDetailsToShow}
                accountProps={{ isPendingModelChangeApproveEnabled: isPendingModalChangeApproveEnabled }}
                accountSummaryGetDigitalWealthAccountsVariables={{ partyId: drawerParams?.partyId ?? '' }}
                accountsFilter={accountsFilter}
                actions={accountSummaryActions}
                assetClassTier={getAssetClassTier}
                contentOptions={contentOptions}
                ctasOverrides={ctasOverrides}
                hiddenSections={['clientInfo', 'supportQuestions']}
                partyId={drawerParams?.partyId ?? ''}
                performanceMethod={PerformanceMethodTypes.FROM_END_OF_DAY_VALUES}
                tradingSuspensionsFilter={isPartnerOpsSuspension}
                viewerPartyId={inContextPartyId}
              />
            </Grid>
          </>
        );
      default:
        return null;
    }
  }, [
    accountDetailsActions,
    accountSummaryActions,
    contentOptions,
    currentDrawerInstance,
    drawerParams,
    getAssetClassTier,
    handleOnRetakeQuestionnaire,
    inContextPartyId,
    isPendingModalChangeApproveEnabled,
  ]);

  return {
    drawerParams,
    closeDrawer,
    currentDrawerComponent,
    handleOnClientClick,
    handleOnAccountClick,
    handleBackClick,
    currentDrawerInstance,
  };
};
